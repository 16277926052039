<template>
  <UnderwriterAutocompleteTypeAhead v-if="underwriterAutocompleteTypeAheadFeatureEnabled" v-bind="$attrs" v-on="$listeners" />
  <UnderwriterAutocompleteEmailSearch v-else v-bind="$attrs" v-on="$listeners" />
</template>

<script>

import UnderwriterAutocompleteEmailSearch from "@/components/form/UnderwriterAutocompleteEmailSearch.vue";
import UnderwriterAutocompleteTypeAhead from "@/components/form/UnderwriterAutocompleteTypeAhead.vue";

export default {
  name: "UnderwriterAutocomplete",
  components: {
    UnderwriterAutocompleteEmailSearch,
    UnderwriterAutocompleteTypeAhead,
  },
  computed: {
    underwriterAutocompleteTypeAheadFeatureEnabled() {
      return this.$store.getters.isFeatureFlagEnabled("UnderwriterAutoComplete")
    }
  }
}
</script>